
























import ModeloGenerico from '@/Generico/ModeloGenerico';
import Vue from 'vue'
import {Prop,Component} from 'vue-property-decorator';

@Component({
    name : "FormularioGenerico"
})
export default class FormularioGenerico extends  Vue
{
    @Prop({type:String,required:true})
    titulo!:string;

    @Prop({type:Object,required:false})
    modelo!:ModeloGenerico

    @Prop({type:Number,required:true})
    action!:number;

    //parametros
    descripcion:string ="";
    id:number = 0;
    guardarInfo()
    {
        if(this.descripcion == "")
        {
            alert('El campo no puede estar vacio');
            return;
        }

        this.$emit('guardar',this.id, this.descripcion);
    }

    //Computed
    get texto()
    {
        if(this.action == 1)
        {
            return "GUARDAR";
        }
        else
        {
            return "EDITAR";
        }
    }
    //Ciclo de vida 
    mounted() {
        if(this.modelo != undefined)
        {
            this.id = this.modelo.id;
            this.descripcion = this.modelo.descripcion;
        }
    }
}
