



















import Vue from 'vue'
import {Prop,Component} from 'vue-property-decorator'
@Component
export default class MensajeConfirmacion extends Vue
{
    @Prop({type:String,required:true})
    mensaje!:string;

    @Prop({type:Boolean,required:true})
    modal!:boolean;

    enviarInfo(parametro:string)
    {
        var devolver = parametro == "S" ? true : false;
        this.$emit('confirmacion',devolver);
    }
}
