import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import { mainAxios } from '@/plugins/axios'
export default class UnidadMedida
{
    unidadMedidaId:number = 0;
    descripcion:string = "";
    estado:boolean = true;
    countActivas:number = 0;
    countInactivas:number =0;


    static async Obtener():Promise<ResponseCustom<UnidadMedida>>
    {
      return await Obtener<UnidadMedida>("UnidadMedida/Obtener",mainAxios);
    }

    static async ObtenerSelect():Promise<ResponseCustom<UnidadMedida>>
    {
      return await Obtener<UnidadMedida>("UnidadMedida/ObtenerActivo",mainAxios);
    }

    static async Guardar(unidadMedida:UnidadMedida):Promise<ResponseGenerico>
    {
        return await Save<UnidadMedida>("UnidadMedida/Guardar",unidadMedida,mainAxios);
    }

    static async EditarEstado(unidadMedida:UnidadMedida):Promise<ResponseGenerico>
    {
        return await Editar<UnidadMedida>("UnidadMedida/EditarEstado",unidadMedida,mainAxios);
    }

    static async Editar(unidadMedida:UnidadMedida):Promise<ResponseGenerico>
    {
        return await Editar<UnidadMedida>("UnidadMedida/Editar",unidadMedida,mainAxios);
    }
}